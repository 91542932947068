<template>
  <Cropper
    ref="cropper"
    field="img"
    :langExt="$t('langExt')"
    :width="width"
    :height="height"
    :noCircle="noCircle"
    img-format="png"
    @close="$emit('close')"
    @crop-success="cropSuccess"
  ></Cropper>
</template>

<script>
import { mapActions } from 'vuex';

import Cropper from '@/components/Common/Cropper';

import { dataURLtoFile } from '@/helpers/file';
import { TOAST_TYPE } from '@/constants/toast';
import { NOTIFICATION } from '@/constants/modules';

export default {
  name: 'ImageUpload',
  components: {
    Cropper,
  },
  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    maxFileSize: {
      type: Number,
      default: 10485760,
    },
    strMaxFileSize: {
      type: String,
      default: '10 MB',
    },
    noCircle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    async cropSuccess(imgDataUrl) {
      const file = await dataURLtoFile(imgDataUrl);
      if (file) {
        this.handleFile(file);
      }
    },
    handleFile(file) {
      if (file.size > this.maxFileSize) {
        this.showToastNotification({
          body: this.$t('error.fileSizeError.customSize', { size: this.strMaxFileSize }),
          config: { type: TOAST_TYPE.ERROR },
        });
        return;
      }

      this.$emit('cropped', file);
    },
    clearFileInput() {
      this.$refs.cropper.setStep(1);
    },
  },
};
</script>

