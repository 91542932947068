<template>
  <AppMenuButton
    :outlined="outlined"
    :data-test-id="microphone"
    :icon="micIcon"
    :border-color="borderColor"
    :color="color"
    item-text-field="label"
    item-value-field="deviceId"
    :selected-item="getSelectedMicrophoneId"
    :item-list="microphoneItems"
    :disabled="!hasMicrophoneAndAllowed"
    @click="toggle"
    @onMenuItemClicked="handleChange"
  ></AppMenuButton>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SELECT_MICROPHONE } from '@/constants/mutation-types';
import { DEVICES } from '@/constants/modules';

export default {
  name: 'MicrophoneSelectButton',
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    prejoin: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(DEVICES, ['getMicrophones', 'getSelectedMicrophoneId', 'hasMicrophone', 'microphoneAllowed']),
    micIcon() {
      return this.isActive ? 'mic-solid' : 'mic-off';
    },
    borderColor() {
      return this.isActive ? 'var(--v-dark-green-base)' : 'var(--v-error-base)';
    },
    color() {
      if (this.iconColor) {
        return this.iconColor;
      }

      return this.isActive ? 'var(--v-dark-white-base)' : 'var(--v-error-base)';
    },
    hasMicrophoneAndAllowed() {
      return this.hasMicrophone && this.microphoneAllowed;
    },
    microphoneItems() {
      return !this.hasMicrophoneAndAllowed ? null : this.getMicrophones;
    },
    microphone() {
      return `${this.type}-microphone-select`;
    },
  },
  methods: {
    ...mapMutations(DEVICES, [SELECT_MICROPHONE]),
    ...mapActions(DEVICES, ['changeDevice']),
    handleChange(microphone) {
      if (this.prejoin) {
        this[SELECT_MICROPHONE](microphone.deviceId);
      } else {
        this.changeDevice(microphone);
      }
    },
    toggle() {
      this.$emit('toggle', !this.isActive);
    },
  },
};
</script>
