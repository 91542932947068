<template>
  <ImageUpload ref="imageCropper" @close="handleAvatarModal" @cropped="uploadAvatar"></ImageUpload>
</template>

<script>
import XperdriveLib from 'xperdrive-lib';
import { mapActions } from 'vuex';

import ImageUpload from '@/components/Common/ImageUpload';

import { NOTIFICATION, SETTINGS, STUDIO } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';
import { isAuthenticated } from '@/helpers/keycloak';
import storage from '@/lib/storage';

export default {
  name: 'AvatarCropperModal',
  components: { ImageUpload },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    ...mapActions(SETTINGS, ['setSettings', 'fetchSettings']),
    ...mapActions(STUDIO, ['setAvatar']),
    async uploadAvatar(file) {
      this.$emit('cropperLoading', true);
      try {
        const formData = new FormData();
        formData.append('formFile', file);
        let res;
        if (isAuthenticated()) {
          res = await XperdriveLib.PublicAvatarUpload(formData);
        } else {
          const { promise } = await XperdriveLib.ImageUploadAnonymous({ width: 500 }, formData);
          res = await promise;
        }
        const avatar = res?.data?.result;
        if (avatar) {
          if (isAuthenticated()) {
            this.userAvatarUpload(avatar);
          } else {
            storage.setItem('avatar', avatar);
            this.$emit('changeAvatar', avatar);
            this.setAvatar(avatar);
          }
          this.clearFileInput();
        } else {
          this.showToastNotification({ body: 'error.avatarUploadError', config: { type: TOAST_TYPE.ERROR } });
        }
      } catch (err) {
        this.clearFileInput();
        console.log(err);
      } finally {
        this.$emit('cropperLoading', false);
      }
    },
    userAvatarUpload(avatar) {
      this.setAvatarSettings(avatar);
    },
    clearAvatar() {
      this.clearFileInput();
      if (isAuthenticated()) {
        this.setAvatarSettings(null);
      } else {
        storage.removeItem('avatar');
        this.setAvatar(null);
        this.$emit('changeAvatar', null);
      }
    },
    clearFileInput() {
      this.$refs.imageCropper.clearFileInput();
    },
    async setAvatarSettings(avatar) {
      try {
        await this.setSettings({
          shortCode: 'avatar',
          state: JSON.stringify(avatar),
        });
        this.setAvatar(avatar);
        await this.fetchSettings();
      } catch (error) {
        console.log(error);
      }
    },
    handleAvatarModal(value) {
      this.$emit('handleAvatarModal', value);
    },
  },
};
</script>

