var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AppMenuButton", {
    attrs: {
      outlined: _vm.outlined,
      "data-test-id": _vm.speaker,
      icon: _vm.micIcon,
      "border-color": _vm.borderColor,
      color: _vm.color,
      "item-text-field": "label",
      "item-value-field": "deviceId",
      "selected-item": _vm.getSelectedSpeakerId,
      "item-list": _vm.getSpeakers,
      disabled: !_vm.hasSpeaker,
    },
    on: { click: _vm.toggle, onMenuItemClicked: _vm.handleChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }