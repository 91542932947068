<template>
  <AppMenuButton
    :outlined="outlined"
    :icon="videoIcon"
    :data-test-id="camera"
    :border-color="borderColor"
    :color="color"
    item-text-field="label"
    item-value-field="deviceId"
    :selected-item="getSelectedCameraId"
    :disabled="!hasCameraAndAllowed"
    :item-list="cameraList"
    @click="toggle"
    @onMenuItemClicked="handleChange"
  ></AppMenuButton>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { DEVICES } from '@/constants/modules';
import { SELECT_CAMERA } from '@/constants/mutation-types';

export default {
  name: 'CameraSelectButton',
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    prejoin: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(DEVICES, ['cameraAllowed', 'getCameras', 'getSelectedCameraId', 'hasCamera']),
    videoIcon() {
      return this.isActive ? 'video-solid' : 'video-off-1';
    },
    borderColor() {
      return this.isActive ? 'var(--v-dark-green-base)' : 'var(--v-error-base)';
    },
    color() {
      if (this.iconColor) {
        return this.iconColor;
      }

      if (!this.hasCameraAndAllowed) {
        return 'theme-dark-blue';
      }
      return this.isActive ? 'var(--v-dark-white-base)' : 'var(--v-error-base)';
    },
    hasCameraAndAllowed() {
      return this.hasCamera && this.cameraAllowed;
    },
    cameraList() {
      return !this.hasCameraAndAllowed ? null : this.getCameras;
    },
    camera() {
      return `${this.type}-camera-select`;
    },
  },
  methods: {
    ...mapActions(DEVICES, ['changeDevice']),
    ...mapMutations(DEVICES, [SELECT_CAMERA]),
    toggle() {
      this.$emit('toggle', !this.isActive);
    },
    handleChange(camera) {
      if (this.prejoin) {
        this[SELECT_CAMERA](camera.deviceId);
      } else {
        this.changeDevice(camera);
      }
    },
  },
};
</script>
