export const MIME_TYPES = {
  jpg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  psd: 'image/photoshop',
};

export const IMAGE_MIME_TYPES = [MIME_TYPES.jpg, MIME_TYPES.png];

export const OVERLAY_MIME_TYPES = [MIME_TYPES.png];

