import { consoleError } from 'xpermeet-lib';

export async function dataURLtoFile(url) {
  try {
    const fileName = `file-${new Date().getTime().toString()}`;
    const res = await fetch(url);
    const blob = await res.blob();
    const file = new File([blob], fileName, { type: 'image/png' });

    return file;
  } catch (err) {
    consoleError(err);
    return null;
  }
}

/**
 *
 * @param  {[String]} data dataURL data:image/png;base64,****”
 * @param  {[String]} mime [description]
 * @return {[blob]}      [description]
 */
export default function dataToBlob(data, mime) {
  data = data.split(',')[1];
  data = window.atob(data);
  var ia = new Uint8Array(data.length);
  for (var i = 0; i < data.length; i++) {
    ia[i] = data.charCodeAt(i);
  }
  // canvas.toDataURL image/png
  return new Blob([ia], {
    type: mime,
  });
}

