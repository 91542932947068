var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Cropper", {
    ref: "cropper",
    attrs: {
      field: "img",
      langExt: _vm.$t("langExt"),
      width: _vm.width,
      height: _vm.height,
      noCircle: _vm.noCircle,
      "img-format": "png",
    },
    on: {
      close: function ($event) {
        return _vm.$emit("close")
      },
      "crop-success": _vm.cropSuccess,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }