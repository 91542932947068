var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ImageUpload", {
    ref: "imageCropper",
    on: { close: _vm.handleAvatarModal, cropped: _vm.uploadAvatar },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }