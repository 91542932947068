<template>
  <div></div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { consoleError } from 'xpermeet-lib';

import { MEDIA_DEVICE_STATE } from '@/constants/devices';
import { UI_ERROR_CODES } from '@/constants/enums';

export default {
  name: 'MediaDevicesInitiator',
  props: {
    cameraDeviceId: {
      type: String,
    },
  },
  data() {
    return {
      cameraPromise: null,
      microphonePromise: null,
      audioStream: null,
      videoStream: null,
    };
  },
  computed: {
    ...mapGetters('Devices', ['getSpeakers']),
    constraints() {
      return {
        video: {
          enable: true,
          width: XPER_CONFIG.serverConfig.constraints.video.width.max,
          height: XPER_CONFIG.serverConfig.constraints.video.height.max,
          deviceId: this.cameraDeviceId,
        },
      };
    },
  },
  async created() {
    const errorCode = this.getUnsupportedDeviceError();

    if (errorCode) {
      this.$emit('initiated', { errorCode });
      return;
    }

    await this.getVideoStatus();
    await this.getMicrophoneStatus();
    this.setDefaultSpeaker();

    this.$emit('initiated', { audioStream: this.audioStream, videoStream: this.videoStream });
  },
  beforeDestroy() {
    this.destroyAudioStreams();
    this.destroyVideoStreams();
  },
  methods: {
    ...mapMutations('Devices', ['SET_DEVICE_STATE', 'SELECT_DEVICE']),
    async getVideoStatus() {
      try {
        const videoStream = await navigator.mediaDevices.getUserMedia(this.constraints);
        this.destroyVideoStreams();
        this.videoStream = videoStream;
        this.$emit('cameraActive', videoStream);
        this.SET_DEVICE_STATE({ type: 'camera', value: MEDIA_DEVICE_STATE.ALLOWED });
      } catch (err) {
        consoleError('Get user media error (Camera): ', err);

        if (err.name === 'NotFoundError') {
          this.SET_DEVICE_STATE({ type: 'camera', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
        } else if (err.name === 'NotAllowedError') {
          this.SET_DEVICE_STATE({ type: 'camera', value: MEDIA_DEVICE_STATE.NOT_ALLOWED_ERROR });
        } else {
          this.SET_DEVICE_STATE({ type: 'camera', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
        }
      }
    },
    async getMicrophoneStatus() {
      try {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.audioStream = audioStream;
        this.SET_DEVICE_STATE({ type: 'microphone', value: MEDIA_DEVICE_STATE.ALLOWED });
      } catch (err) {
        consoleError('Get user media error (Microphone): ', err);

        if (err.name === 'NotFoundError') {
          this.SET_DEVICE_STATE({ type: 'microphone', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
        } else if (err.name === 'NotAllowedError') {
          this.SET_DEVICE_STATE({ type: 'microphone', value: MEDIA_DEVICE_STATE.NOT_ALLOWED_ERROR });
        } else {
          this.SET_DEVICE_STATE({ type: 'microphone', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
        }
      }
    },
    setDefaultSpeaker() {
      const [defaultSpeaker] = this.getSpeakers;

      if (defaultSpeaker) {
        this.SELECT_DEVICE(defaultSpeaker);
      }
    },
    getUnsupportedDeviceError() {
      if (!navigator?.mediaDevices) {
        return UI_ERROR_CODES.UI_ERROR_CODES.UNSUPPORTED_DEVICE;
      }

      return null;
    },
    switchLocalTracks() {
      this.destroyVideoStreams();
      this.cameraPromise = navigator.mediaDevices
        .getUserMedia(this.constraints)
        .then((mediaStream) => {
          this.videoStream = mediaStream;
        })
        .catch((err) => {
          if (err.name === 'NotFoundError') {
            this.SET_DEVICE_STATE({ type: 'camera', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
          } else if (err.name === 'NotAllowedError') {
            this.SET_DEVICE_STATE({ type: 'camera', value: MEDIA_DEVICE_STATE.NOT_ALLOWED_ERROR });
          } else {
            this.SET_DEVICE_STATE({ type: 'camera', value: MEDIA_DEVICE_STATE.NOT_FOUND_ERROR });
          }
        });

      Promise.all([this.cameraPromise, this.microphonePromise]).then(() => {
        this.$emit('initiated', { audioStream: this.audioStream, videoStream: this.videoStream });
      });
    },
    destroyAudioStreams() {
      if (this.audioStream) {
        const audioTracks = this.audioStream.getTracks();
        audioTracks.forEach((t) => {
          t.stop();
        });
      }
    },
    destroyVideoStreams() {
      if (this.videoStream) {
        const videoTracks = this.videoStream.getVideoTracks();
        videoTracks.forEach(async (t) => {
          t.stop();
        });
      }
    },
  },
  watch: {
    cameraDeviceId() {
      try {
        if (this.cameraDeviceId !== this.videoStream.getVideoTracks()[0].getSettings().deviceId) {
          this.switchLocalTracks();
        }
      } catch (e) {
        this.switchLocalTracks();
      }
    },
  },
};
</script>

<style scoped></style>

